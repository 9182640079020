@import '../variables.less';

.vocabulary-panel {
  min-width: 350px;
  margin-top: 20px;
  padding: 0 10px 20px;

  .container {
    margin: 0 auto;
    max-width: 507px;
  }

  .vocabulary-toolbar {
    display: flex;
    align-items: center;
    padding: 18px 3px 10px 5px;
    position: sticky;
    top: 0;
    z-index: 1;
    background: var(--bg-color);
    @media (max-width: 800px) {
      background: var(--mobile-vocab-bg);
    }
  }

  &.scrolling {
    .vocabulary-toolbar {
      border-bottom: 1px solid var(--subtle-shadow-color);
    }
  }

}

.vocabulary-select .MuiSvgIcon-root {
  right: 11px !important;
}

.current-vocab {
  background: #888;
}

.vocab-menu-title {
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  //noinspection CssInvalidPropertyValue
  -webkit-mask-image: linear-gradient(to left, transparent 0%, var(--bg-color) 10%); /* For Chrome and Safari */
  //mask-image: linear-gradient(to left, transparent, white); /* Standard syntax */
  padding-right: 20px; // compensate for the mask
}
