.vocabulary-wrap {
  padding: 10px 0;
}

.cards-demo {
  line-height: 1.4;

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
}
