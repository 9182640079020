.source-dropdown {
  .MuiSelect-select {
    padding-left: 5px !important;
    padding-right: 17px !important;
  }
  .MuiSvgIcon-root {
    right: -5px;
  }
}

.target-dropdown {
  .MuiSelect-select {
    padding-left: 0;
    padding-right: 25px !important;
  }
  .MuiSvgIcon-root {
    right: 3px;
  }
}
