body{
  color: var(--text-color);
  background-color: var(--bg-color);
  min-width: 320px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

/* removes Chrome's annoying [       x ] */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.MuiInputBase-root {
  background-color: var(--bg-color);
}

.MuiInputBase-input {
  padding-top: 11.5px !important;
  padding-bottom: 11.5px !important;
}

.MuiInputAdornment-root {
  margin-left: 0px !important;
}

.MuiToolbar-root {
  min-height: 50px !important;
}

.MuiInputLabel-outlined {
  background: var(--bg-color);
  padding: 0 5px !important;
  margin-left: -3.7px !important;
}
.MuiMenuItem-root {
  padding: 15px 30px !important;
}

.emphasized-button {
  margin: 0 20px !important;
  transform: scale(1.25);
}