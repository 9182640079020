@import (reference) '../variables.less';

.search-panel, .vocabulary-panel {
  overflow-y: auto;
  overflow-x: hidden;

  position: fixed;
  top: calc(@navbar-height + env(safe-area-inset-top));
  bottom: 0;
}

.search-panel {
  padding: 20px 20px 20px 5px;
}

.search-panel .MuiInputBase-adornedEnd {
  padding-right: 10px !important;
}

.toggle-vocabulary {
  display: none !important;
}

@media (min-width: 1101px) {
  .search-panel {
    right: 45%;
    width: 550px;
  }

  .vocabulary-panel {
    left: 55%;
    width: 400px;
  }
}

@media (min-width: 800px) and (max-width: 1100px) {
  .search-panel {
    right: 50%;
    left: 2%;
  }

  .vocabulary-panel {
    right: 5%;
    left: 50%;
    max-width: 400px;
  }
}

@media (max-width: 800px) {
  .toggle-vocabulary{
    display: block !important;;
  }
  @keyframes slideInFromLeft {
    0% {
      left: -100%;
    }
    100% {
      left: 0;
    }
  }
  body {
    overflow-x: hidden; // so the page does not scroll
  }

  .mobile-search {
    margin: 0 auto;

    .search-panel {
      padding-top: 0;
      display: block;
      width: calc(100vw - 10px);
      background: var(--bg-color);
      z-index: 1;
      animation: slideInFromLeft 0.2s ease-in-out forwards;

      //&.closing {
      //  animation: slideInFromLeft 0.5s ease-in-out backwards;
      //}
    }
  }

  .search-panel {
    display: none;
  }

  .vocabulary-panel {
    margin-top: 0;
    padding-left: 5px;
    left: 0;
    right: 0;
    background: var(--mobile-vocab-bg);
  }
}
