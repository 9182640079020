.search-tabs {
  margin: 16px 0;
  .MuiTab-root {
    flex-direction: row;
  }
  .MuiSvgIcon-root {
    font-size: 15px;
    padding: 4px;
    margin-top: -1px;
  }
}