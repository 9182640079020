.pager {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .cards-page {
    padding: 0 !important;
  }
}

.cards-page .action {
  height: 90px;
  width: 90px;
  border: 1px solid;
  border-radius: 50%;

  &.small {
    height: 40px;
    min-width: auto;
    width: 40px;
  }

  &.MuiButton-contained {
    border: none;
  }
}