@import '../variables.less';

.extractor {
  color: var(--primary-color);
  // font-family: Lucida grande;
  font-weight: bold;
  margin: 20px 5px 10px 0;
  // display: inline-block;
  a {
    color: var(--primary-color);
  }
}

.search-results {
  margin-bottom: 10px;

  .meaning-bundle {
    padding: 5px 10px;
    line-height: 17px;
    list-style: none;
  }

  .POS, .pronunciation, .qualification, .reverse, .users {
    color: var(--soft-text-color);
    font-style: italic;
  }

  .word {
    font-weight: bold;
  }

  ol {
    margin: 5px 0;
    padding-left: 20px;

    &.submeanings {
      /*margin-top: -14px;*/
      list-style-type: lower-alpha;
      margin-left: -10px;
    }

    &.image-list {
      padding-left: 0;

      li {
        list-style: none;
        margin-left: 0;
      }
    }
  }

  li {
    margin-left: 5px;
    margin-bottom: 5px;
  }

  li:first-child:last-child {
    list-style: none;
  }

  img {
    height: 25px;
    margin-left: 5px;
  }
}
