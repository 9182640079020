.Vocabulary {

  /* divtable */
  position: relative;

  &.hide-selectors .selector {
    display: none;
  }

  .table {
    display: table;
    border-collapse: collapse;
    max-width: 507px;
    background: var(--bg-color);
    position: relative; /* so that the right border gets rendered */
  }

  .tr {
    display: table-row;

    // d-n-d
    &.hover {
      border-bottom: 7px solid var(--soft-text-color);
      box-shadow: 0px 4px 8px #ccf;
    }

    &.active {
      margin-bottom: 5px;
    }

    &.ui-selecting {
      background: var(--border-color);
    }

    &.ui-selected {
      background: var(--subtle-shadow-color);
    }
    &.ui-dragging {
      background: var(--bg-color);
      box-shadow: 1px 5px 7px var(--shadow-color);
    }

    &.preview {
      background-color: rgba(0, 107, 133, 0.04);

      .td {
        color: var(--soft-text-color);
      }
    }

    &.spacer {
      height: 0px;

      .td {
        height: 10px;
        border: 0px;
      }
    }
  }

  .td {
    display: table-cell;
    text-align: left;
    color: var(--text-color);
    height: 30px;
    border: 1px solid var(--border-color);
    padding: 6px 5px 4px;
    vertical-align: middle;

    &[contenteditable='true'] {
      width: 49%;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);

      &:hover {
        box-shadow: 1px 1px 2px var(--subtle-shadow-color);
        background-color: var(--bg-color);
        transition: 0.1s;
      }

      &:focus {
        border: 1px solid rgba(52, 90, 187, 0.5);
        outline: none;
        box-shadow: 0px 0px 1px 2px var(--primary-color);
        background-color: var(--bg-color);
      }
    }

    &.selector {
      width: 2%;
      padding: 0;

      &:hover .grab {
        border-color: var(--highlighted-border-color);
      }

      .grab {
        width: 3px;
        height: 11px;
        border: 1px dotted var(--border-color);
        border-width: 0px 1px;
        margin: 1px 4px 0;
      }
    }

    img {
      margin: 2px 4px;
      vertical-align: middle;
      width: 30px;
      float: right;
      -webkit-filter: grayscale(5%);
    }
  }

  .tools {
    display: none;
  }

  &.show-tools {
    .tools {
      display: block;
      margin-left: -40px;
    }

    .source {
      border-right: none;
    }
  }

  .last-selected .td {
    border-bottom: 1px solid var(--highlighted-border-color); /*#888*/
  }

  .cell-menu {
    padding: 1px;
    width: 30px;
    height: 20px;
    position: absolute;
    display: block;
    z-index: 9;
  }
}
