@navbar-height: 50px;

:root {
  --primary-color: #2a97ce;
  --text-color: #333;
  --soft-text-color: #777;
  --bg-color: #fff;
  --mobile-vocab-bg: #faf9f8;
  --border-color: #ccc;
  --shadow-color: #ccc;
  --subtle-shadow-color: #eee;
  --card-border-color: #aaa;
  --card-shadow-color: #bbb;
  --highlighted-border-color: #555;
}

[data-theme='dark'] {
  --primary-color: #2a97ce;
  --text-color: #ddd;
  --soft-text-color: #999;
  --bg-color: #222;
  --mobile-vocab-bg: #222;
  --border-color: #777;
  --card-border-color: #999;
  --card-shadow-color: #777;
  --shadow-color: #aaa;
  --subtle-shadow-color: #444;
  --highlighted-border-color: #ddd;
}
