.topbrand {
  position: absolute;
  font-size: 9px;
  top: 3px;
  left: 45px;
}

.navbar-brand {
  // color: #e8f4fa;
  color: #fff;
  position: relative;
  font-size: 20px;
  text-decoration: none;

  img {
    opacity: .95;
    height: 40px;
    margin: -5px 5px -5px 0;
  }
}
