@import "../variables.less";

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.fade-out {
  animation-name: fadeOut;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.play{
  width: calc(100vw - 20px);
  max-width: 420px;
}
@media(max-width: 768px){
  .play{
    height: calc(100vh - @navbar-height - 20px - env(safe-area-inset-bottom) - env(safe-area-inset-top));
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

.question-arrow-down{
  transform: scale(2) translateX(-5px);
}
.cardshow {
  min-height: 200px;

  .display {
    margin-top: 10px;
    max-width: 470px;
    height: 280px;
    padding: 10px;
    overflow: hidden;
    border: 1px solid var(--border-color);
    border-bottom: 0;
    width: auto;
  }

  .card-container {
    padding: 5px 7px;
    position: relative;
    height: 100%;
  }

  .card {
    border: 1px solid var(--card-border-color);
    background: var(--bg-color);
    position: absolute;
    left: 6px;
    top: 6px;
    width: 92%;
    height: 90%;
    padding: 10px;
    box-shadow: -1px -1px 1px var(--card-shadow-color);
    font-size: 20px;

    overflow: hidden;
    text-align: left;
    vertical-align: top;

    img {
      display: inline;
      max-height: 50px;
      max-width: 130px;
      margin: 3px;
    }
  }

  .shadow1 {
    left: 3px;
    top: 3px;
  }

  .shadow2 {
    background: var(--subtle-shadow-color);
    left: 0px;
    top: 0px;
  }

  .cover {
    position: absolute;
    left: 12px;
    padding-top: 50px;

    i {
      font-size: 30px;
    }

    &.animate {
      transition: all cubic-bezier(0.2, 0.460, 0.450, 0.940) 0.06s;
      transition-delay: 0.1s;
    }

    &.up {
      top: 50%;
    }

    &.down {
      transition: top 0.2s;
      top: 97%;
    }
  }

  .inner {
    -ms-animation-duration: 500ms;
    -o-animation-duration: 500ms;
    -webkit-animation-duration: 500ms;
    -moz-animation-duration: 500ms;
    animation-duration: 500ms;
  }

  .info, .secret {
    display: block;
    text-align: center;
    height: 50%;
    padding: 8px 0;
    overflow-y: auto;
    position: relative;
    .tools{
      position: absolute;
      right: 5px;
      top: 0;
    }
  }
  .displayed-value[contenteditable="true"]{
    height: calc(100% - 10px);
    padding: 5px;
    margin-top: -6px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    transition: border 0.2s;
  }
  .displayed-value[contenteditable="true"]:focus{
    border: 1px solid var(--primary-color);
    outline: none;
  }
  .secret .displayed-value[contenteditable="true"]{
    height: calc(100% - 30px);
  }

  .secret {
    table {
      width: 100%;
      border-collapse: collapse;
    }
  }

  .navigate {
    position: relative;
    max-width: 470px;
    margin: 0;
    padding-top: 5px;
    text-align: center;
    border: 1px solid var(--border-color);
  }

  .Mui-disabled .MuiSvgIcon-root{
      color: var(--bg-color);
  }

  .progress-info {
    margin: 5px;
    color: var(--soft-text-color);
  }

  .progress {
    height: 8px;
    margin: 16px 0;
    display: inline-block;
    width: 80%;
    border: 1px solid var(--border-color);
    border-radius: 50px;
    box-shadow: none;
    background-color: var(--bg-color);
  }
  .progress-bar {
    height: 100%;
    background-color: var(--primary-color);
    border-radius: 50px;
  }
}

.ZhCharactersGame {
  .info {
    height: 25%;
    font-size: 25px;
  }

  .secret {
    height: 75%;
    font-size: 15px;
  }

  .cover.up {
    top: 25%;
    padding-top: 100px;
  }

  td {
    word-wrap: anywhere;
    width: 50%;
    text-align: left;
    vertical-align: top;
    border: 1px solid var(--subtle-shadow-color);
    padding: 5px;
  }
}

.cardshow {
  position: relative;

  .celebration {
    position: absolute;
    top: 70px;
    text-align: center;
    width: 100%;
    font-size: 35px;
    color: #ffb90f;
    font-weight: bold;

    &.changed {
      z-index: 1;
    }
  }
}

.hidePinyin .pinyin {
  display: none;
}
